import { FaLinkedin } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa6";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { RiMailUnreadLine } from "react-icons/ri";
const Footer = () => {
  return (
    <div
      className="main-footer"
      style={{
        backgroundImage:
          "linear-gradient(to bottom right,  rgba(47, 255, 134, 0.304) , rgba(170, 168, 168, 0)",
        padding: "70px 0 40px 0",
        fontFamily: "Trebuchet MS",
      }}
    >
      <div className="container">
        <div className="row" style={{ display: "flex" }}>
          <div className="col-md-3 col-sm-6">
            <h4 className="contactH4" style={{ marginRight: "56%" }}>
              Contact Me
            </h4>
            <ul className="list-unstyled">
              <li>Phone : (469) 563 0536</li>
              <li>Email : pnakhon04@gmail.com</li>
              <li>
                Linkedin : www.linkedin.com/in/pisit-nakhonekhong-613405283
              </li>
              <li>Personal Website:</li>
            </ul>
          </div>

          <div className="iconsProfile">
            <a href="https://www.linkedin.com/in/pisit-nakhonekhong-613405283/">
              <FaLinkedin className="ico" />
            </a>

            <a href="https://github.com/HarryNakhone">
              {" "}
              <FaGithub className="ico" />
            </a>
            <div>
              <FaPhoneSquareAlt />
            </div>

            <a href="https://mail.google.com/">
              {" "}
              <RiMailUnreadLine className="ico" />
            </a>
          </div>
        </div>
        <div className="footer-bottom">
          <p className="text-xs-center">
            &copy;{new Date().getFullYear()} harrynakho.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
