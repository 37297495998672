const Hobbies = () => {
  return (
    <div className="parentHobbies">
      <div className="hobbiesContainer">
        <div className="lift">
          <img
            src="./images/IMG-9988.PNG"
            alt="school"
            width={500}
            height={"auto"}
          />
          <p className="para">
            I'm currently focused on completing my Bachelor's degree in Computer
            Science at Dallas Baptist University. My journey in pursuing this
            degree began after graduating from Trinity Christian School in Cedar
            Hill in 2021, where I earned my diploma. Now, as a junior, I'm
            delving deeper into the intricacies of computer science, exploring
            various programming languages, algorithms, and software development
            methodologies. I'm passionate about leveraging technology to solve
            real-world problems and am eager to continue learning and growing in
            this dynamic field.
          </p>
        </div>
        <div className="coding">
          <img src="./images/code.JPG" alt="codes" width={700} />
          <p className="para">
            " You know how some people get really into solving puzzles or fixing
            things around the house? Well, for me, that's what coding is like.
            It's all about figuring stuff out and making things work, but
            instead of tools or clues, you've got lines of code on your computer
            screen. It's like learning a new language, but instead of talking to
            people, you're talking to your computer to make it do cool stuff.
            Whether it's building websites, apps, or even little games, there's
            always something new to learn and try out. And the best part is,
            you're part of this big community where everyone's helping each
            other out and sharing ideas. So yeah, coding's not just a hobby for
            me; it's like this never-ending adventure where I get to create
            stuff and learn along the way. "
          </p>
        </div>

        <div className="bake">
          <img src="./images/bake.JPG" alt="baking" width={700} />
          <p className="para">
            " When I'm not buried in code, I like to be in a kitchen whipping up
            something delicious. I'm a bit of a foodie, and there's nothing
            quite like the satisfaction of baking up a storm or trying out a new
            recipe. It's my way of unwinding and getting those creative juices
            flowing. I love to share my bakig creation to my friends and family.
            It would really be nice if I could work at a company where I can
            share my baking to the team the the people around me! "
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hobbies;
