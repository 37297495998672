import { useEffect, useState } from "react";
import Recipe from "./Hobbies";
import { MdCircle } from "react-icons/md";
import Card from "react-bootstrap/Card";
import { FaReact } from "react-icons/fa";
import { IoLogoHtml5 } from "react-icons/io5";
import { FaCss3Alt } from "react-icons/fa";
const Main = () => {
  const [recipes, setRecipes] = useState([]);

  return (
    <div className="mainPage">
      <div className="pictureAndDesc">
        <div className="picture"> .</div>
        <div className="desc">
          <h3>
            Hello,{" "}
            <span className="circle1">
              <MdCircle />
            </span>
          </h3>
          <h1>
            I'm <span className="name">Harry</span>
            <span className="circle2">
              <MdCircle
                style={{
                  color: "rgb(91, 255, 200)",
                }}
              />
            </span>
            <span className="circle3">
              <MdCircle
                style={{
                  color: "rgb(91, 255, 200)",
                  fontSize: "50px",
                }}
              />
            </span>
          </h1>
          <h5>Pisit Nakhonekhong</h5>
          <h1>
            Computer Science <span className="student">Student</span> At DBU
          </h1>
          <p>
            I am a skilled and passionate computer science student with
            experience of creating my own user-friendly web app and projects.
          </p>
        </div>
      </div>

      <div className="skillBox">
        <div className="front">
          <Card style={{ width: "30rem" }}>
            <Card.Header>SKILL 1</Card.Header>
            <Card.Body>
              <Card.Title>FRONT-END</Card.Title>
              <Card.Text>
                React.js, React Native, HTML, CSS, Bootstraps, StyledComponents
              </Card.Text>
            </Card.Body>
          </Card>
          <img
            src="/images/React-icon.svg.png"
            width={100}
            height={100}
            alt="react"
          />
          <img src="/images/html_logo.jpg" width={160} alt="html" />
          <img
            src="/images/CSS3_logo.svg.png"
            width={80}
            height={100}
            alt="css"
          />
        </div>

        <div className="back">
          <Card style={{ width: "30rem" }}>
            <Card.Header>SKILL 2</Card.Header>
            <Card.Body>
              <Card.Title>BACK-END</Card.Title>
              <Card.Text>NodeJS, ExpressJS, MySQL, SQL</Card.Text>
            </Card.Body>
          </Card>
          <img
            src="/images/node_logo.png"
            width={170}
            height={100}
            alt="node js"
          />
          <img
            src="/images/expressjs.png"
            width={140}
            height={100}
            alt="express"
          />
          <img
            src="/images/Mysql_logo.png"
            width={120}
            height={100}
            alt="mysql"
          />
        </div>

        <div className="language">
          <Card style={{ width: "30rem" }}>
            <Card.Header>LANGUAGES</Card.Header>
            <Card.Body>
              <Card.Title>CODING LANGUAGES</Card.Title>
              <Card.Text>C++, Python, Java, Javascript, TypeScript</Card.Text>
            </Card.Body>
          </Card>
          <img src="/images/logoC++.png" width={70} height={100} alt="c++" />
          <img src="/images/python_logo.png" width={80} height={100} alt="py" />
          <img
            src="/images/java_logo.jpg"
            width={138}
            height={100}
            alt="java"
          />
          <img
            src="/images/javascript_logo.png"
            width={120}
            height={100}
            alt="js"
          />

          <div className="skillIcon"></div>
        </div>
      </div>
    </div>
  );
};

export default Main;
