import "./App.css";
import "./Project.css";
import "./Main.css";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import "./Footer.css";
import { Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import "./Hobbies.css";
import Project from "./components/Project";
import Hobbies from "./components/Hobbies";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Main />} />
        <Route path="/project" element={<Project />} />
        <Route path="/hobbies" element={<Hobbies />} />
      </Route>
    </Routes>
  );
}

export default App;
