import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <Navbar
      expand="lg"
      style={{
        backgroundImage:
          "linear-gradient(to right,  rgba(47, 255, 134, 0.304) , rgba(170, 168, 168, 0)",
      }}
    >
      <Container fluid>
        <Navbar.Brand
          as={Link}
          to="/"
          style={{
            fontFamily: "Times New Roman",
            fontSize: "50px",
            fontWeight: "bold",
          }}
        >
          PN
        </Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link as={Link} to="/project">
            Projects
          </Nav.Link>
          <Nav.Link as={Link} to="/hobbies">
            Hobbies
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
