import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";

const Project = () => {
  return (
    <Container className="projectContainer">
      <h1>
        {" "}
        <a
          href="https://github.com/HarryNakhone"
          style={{ textDecoration: "none", padding: "30px", margin: "20px" }}
        >
          {" "}
          Click here to see my Github
        </a>
      </h1>
      <Row className="row1">
        <Col md={6} className="col1">
          <h1>
            SHOPPING CART <span className="span5">DEMO </span>
          </h1>
          <div className="imageBox">
            <img
              src="./images/shop1.png"
              alt="shopping-app"
              width={700}
              height={400}
              className="shop1 img-fluid"
            />
            <img
              src="./images/shop3.png"
              alt="shopping-app"
              width={700}
              height={400}
              className="shop3 img-fluid"
            />
          </div>

          <img
            src="./images/shop2.png"
            alt="shopping-app"
            width={700}
            height={400}
            className="shop2 img-fluid"
          />
        </Col>
        <Col
          md={6}
          style={{
            height: "70rem",
            paddingBottom: "10rem",
            display: "flex",
            flexDirection: "column",
            paddingLeft: "40px",
          }}
        >
          <div className="text1ofShop">
            <h3>
              Streamlined Shopping Cart Web Application for Enhanced User
              Experience
            </h3>
            <h3>
              Effortlessly Manage Items: Add and Remove from Your Cart with Ease
            </h3>
          </div>

          <h3 className="text2ofShop">
            Instant Item Discovery: Utilize a Dynamic Search Bar for Real-Time
            Product Retrieval
          </h3>
        </Col>
      </Row>

      <Row className="row2">
        <Col className="col1ofWea">
          <h3>Discover Your Daily Forecast with Our Weather App</h3>
          <h3>Powered by Real-Time Data from an Accurate API Source</h3>
        </Col>
        <Col className="col2ofWea">
          <h1>
            WEATHER <span className="span5">APP</span>
          </h1>
          <div className="imageBox">
            <img
              src="./images/weather1.png"
              alt="weather-app"
              width={800}
              height={400}
              className="weather1 img-fluid"
            />
            <img
              src="./images/weather2.png"
              alt="weather-app"
              width={800}
              height={400}
              className="weather2 img-fluid"
            />
          </div>
        </Col>
      </Row>

      <Row className="row3">
        <Col className="col1ofSlot">
          <h1>
            SLOT <span className="span5">MACHINE</span>
          </h1>
          <div className="imageBox">
            <img
              src="./images/slotmachine.png"
              alt="slot-app"
              width={800}
              height={400}
              className="slot1 img-fluid"
            />
            <img
              src="./images/slot.png"
              alt="slot-app"
              width={800}
              height={400}
              className="slot2 img-fluid"
            />
          </div>
        </Col>
        <Col className="col2ofSlot">
          <h3>
            Sloth machine games allow users to input their desired bet amount
            before spinning the reels, providing an interactive experience where
            players can control their wager and potential winnings.
          </h3>
        </Col>
      </Row>
    </Container>
  );
};

export default Project;
